/**Styles for Sticky Header**/
.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 999;
}
.background {
    background: #efe711;
    color: #000;
}
.content {
    padding: .75rem 0;
    line-height: 1.25;
    text-align: center;
}
